// 默认菜单，没有从后台获取到使用的备用
const menus: any = [
  {
    children: [
      {
        disable: false,
        icon: null,
        idx: "/a1/c2",
        isLink: false,
        meta: {},
        name: "申请请假/销假记录",
        openType: 1,
        path: "holiday?",
        path0: "holiday",
      },
      {
        disable: false,
        icon: null,
        idx: "/a1/c3",
        isLink: false,
        meta: {},
        name: "申请补班记录",
        openType: 1,
        path: "makeup",
        path0: "makeup",
      },
      {
        disable: false,
        icon: null,
        idx: "/a1/c4",
        isLink: false,
        meta: {},
        name: "申请加班记录",
        openType: 1,
        path: "workHourRewards",
        path0: "workHourRewards",
      },
      {
        disable: false,
        icon: null,
        idx: "/a1/c5",
        isLink: false,
        meta: {},
        name: "任务暂停记录",
        openType: 1,
        path: "taskPaused",
        path0: "taskPaused",
      },
    ],
    icon: null,
    idx: "/a1",
    meta: {},
    name: "触点综合申请管理",
  },
  {
    children: [
      {
        disable: false,
        icon: null,
        idx: "/a6/c7",
        isLink: false,
        meta: {},
        name: "任务执行管理列表",
        openType: 1,
        path: "taskExecution?",
        path0: "taskExecution",
      },
    ],
    icon: null,
    idx: "/a6",
    meta: {},
    name: "任务执行管理",
  },
];
export default menus;
