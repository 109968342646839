// 应用路由
const routers = [
  {
    // acc表
    path: "/photo",
    name: "photo",
    meta: { hidden: false },
    component: () => import("@/views/photo/index.vue"),
  },
  {
    // acc表
    path: "/photo/upload",
    name: "photo_upload",
    meta: { hidden: false },
    component: () => import("@/views/photo/modules/Upload.vue"),
  },
  {
    // 请假
    path: "/holiday",
    name: "holiday",
    meta: { hidden: false },
    component: () => import("@/views/Holiday/index.vue"),
  },
  {
    // 补班
    path: "/makeup",
    name: "makeup",
    meta: { hidden: false },
    component: () => import("@/views/MakeUp/index.vue"),
  },
  {
    // 工时奖励
    path: "/workHourRewards",
    name: "workHourRewards",
    meta: { hidden: false },
    component: () => import("@/views/WorkHourRewards/index.vue"),
  },
  {
    // 任务暂停
    path: "/taskPaused",
    name: "taskPaused",
    meta: { hidden: false },
    component: () => import("@/views/TaskPaused/index.vue"),
  },

  {
    // 任务执行管理
    path: "/taskExecution",
    name: "taskExecution",
    meta: { hidden: false },
    component: () => import("@/views/TaskExecution/index.vue"),
  },
];
export default routers;
